const Emoji = require("regex-emoji");
const Match = require("match-all");
const Barbe = require("barbe");
const Map = require("gh-emojis/emojis.json");

function format(message, template) {
    const results = {};

    Match(message, Emoji()).toArray().forEach((item) => {
        if (!Map[item]) return;

        results[item] = Barbe(template, ["__", "__"], { EMOJI_NAME: Map[item] });
    });

    return Barbe(message, [":", ":"], results);
};

module.exports = () => {
    return [{
        type: "output",
        filter: (text) => format(text, "<img src=\"__EMOJI_NAME__\" class=\"emoji-img emoji\">"),
    }];
};
