import "../lib/extentions";
import "../lib/dates";

import Vue from "vue";
import Request from "axios";
import Vuelidate from "vuelidate";

import { Base64 } from "js-base64";

import App from "./app.vue";
import Router from "./router";
import Security from "./security";
import Markdown from "../lib/markdown";
import Authentication from "../lib/auth";

import Logo from "@/components/logo.vue";
import Sitemap from "@/components/sitemap.vue";
import Searchbar from "@/components/searchbar.vue";
import Upload from "@/components/upload.vue";

import "materialize-css";
import "@/assets/materialize.css";

const PRIMARY = new RegExp([
    "(android|bb\\d+|meego).+mobile|avantgo|bada\\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|",
    "ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|",
    "p(ixi|re)\\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\\.(browser|link)|vodafone|wap|windows ce|",
    "xda|xiino|ipad",
].join(""), "i");

const SECONDARY = new RegExp([
    "1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny",
    "|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|",
    "bw-(n|u)|c55\\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob",
    "|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|",
    "gf-5|g-mo|go(\\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|",
    "tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|",
    "jigs|kddi|keji|kgt( |\\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|",
    "m3ga|m50\\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|",
    "mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|",
    "nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|",
    "prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\\/|sa(ge|ma|mm|ms|",
    "ny|va)|sc(01|h-|oo|p-)|sdk\\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|",
    "so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|",
    "ts(70|m-|m3|m5)|tx-9|up(\\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|",
    "60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-",
].join(""), "i");

(async () => {
    const manager = new Authentication();

    Vue.mixin({
        data: () => {
            return {
                api: {
                    async get(url) {
                        let token = await manager.acessToken();

                        if (!token) token = await Security.token();

                        return (await Request.get(`${process.env.API_URL}/api${url}`, { headers: { "Authorization": `${token.token_type} ${token.access_token}` } })).data;
                    },

                    async post(url, data) {
                        let token = await manager.acessToken();

                        if (!token) token = await Security.token();

                        return (await Request.post(`${process.env.API_URL}/api${url}`, data, { headers: { "Authorization": `${token.token_type} ${token.access_token}` } })).data;
                    },

                    async put(url, data) {
                        let token = await manager.acessToken();

                        if (!token) token = await Security.token();

                        return (await Request.put(`${process.env.API_URL}/api${url}`, data, { headers: { "Authorization": `${token.token_type} ${token.access_token}` } })).data;
                    },

                    async delete(url) {
                        let token = await manager.acessToken();

                        if (!token) token = await Security.token();

                        return (await Request.delete(`${process.env.API_URL}/api${url}`, { headers: { "Authorization": `${token.token_type} ${token.access_token}` } })).data;
                    },

                    async download(id, filename) {
                        let token = await manager.acessToken();

                        if (!token) token = await Security.token();

                        const content = new Blob(
                            [(await Request.get(`${process.env.API_URL}/static/${id}`, {
                                responseType: "arraybuffer",
                                headers: {
                                    "Authorization": `${token.token_type} ${token.access_token}`,
                                    "Content-Type": "application/octet-stream",
                                    "Accept": "application/octet-stream",
                                },
                            })).data],
                            { type: "application/octet-stream" },
                        );

                        const link = document.createElement("a");
                        const href = URL.createObjectURL(content);

                        document.body.appendChild(link);

                        link.style = "display: none";
                        link.href = href;
                        link.download = filename;
                        link.click();
                        link.remove();

                        window.URL.revokeObjectURL(href);
                    }
                },

                $token: {
                    id() {
                        manager.idToken().then((results) => resolve(results), () => resolve(null));
                    },

                    access() {
                        manager.acessToken().then((results) => resolve(results), () => resolve(null));
                    }
                }
            }
        },

        computed: {
            $mobile: () => {
                let check = false;

                ((agent) => {
                    if (PRIMARY.test(agent) || SECONDARY.test(agent.substring(0, 4))) check = true;
                })(navigator.userAgent || navigator.vendor);

                return check;
            },

            $platform: () => {
                let value = "desktop";

                ((agent) => {
                    if (PRIMARY.test(agent) || SECONDARY.test(agent.substring(0, 4))) {
                        value = "mobile";

                        if (tablet.test(agent)) value = "tablet";
                    }
                })(navigator.userAgent || navigator.vendor);

                return value;
            },

            $authenticated: () => {
                return new Promise((resolve) => {
                    manager.authenticated().then((result) => resolve(result), () => resolve(false));
                });
            }
        },

        methods: {
            $user: () => {
                return new Promise((resolve) => {
                    manager.user().then((results) => resolve(results), () => resolve(null));
                });
            },

            async $login() {
                const request = await manager.logoutRedirect();

                await Request.get(request);
                await manager.removeUser();

                manager.login();
            },

            async $logout() {
                manager.logout();
            },

            $profile(claim) {
                return new Promise((resolve) => {
                    manager.profile(claim).then((result) => resolve(result), () => resolve(null));
                });
            },

            $hasRole(role) {
                return new Promise((resolve) => {
                    manager.roles().then((result) => resolve(result.indexOf(role) >= 0), () => resolve(false));
                });
            },

            $markdown(value, repository, branded) {
                return Markdown.format(value, repository, branded);
            },

            $encode(value) {
                if (!value) return "";

                while (value.endsWith("\\") || value.endsWith("/")) {
                    value = value.slice(0, -1);
                }

                while (value.startsWith("*")) {
                    value = value.substring(1);
                }

                if (!value || value === "") return "";

                return Base64.encodeURI(value);
            },

            $decode(value) {
                return Base64.decode(value);
            }
        }
    });

    Vue.component("logo", Logo);
    Vue.component("sitemap", Sitemap);
    Vue.component("searchbar", Searchbar);
    Vue.component("upload", Upload);

    Vue.use(Vuelidate);

    Vue.config.productionTip = false;

    new Vue({ router: Router, render: view => view(App) }).$mount("#app");
})();
