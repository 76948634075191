module.exports = {
    repository(value) {
        if (!value) return undefined;
        if (!value.url || value.url === "") return undefined;

        value.url = value.url.trim()
            .replace("git@github.com:", "https://github.com/")
            .replace("git@gitlab.com:", "https://gitlab.com/")
            .replace("git://github.com/", "https://github.com/")
            .replace("git://gitlab.com/", "https://gitlab.com/")
            .replace("git+", "");

        if (value.url.indexOf("https://") === -1 && value.url.indexOf("http://") === -1) return undefined;

        try {
            const parts = value.url
                .replace("git@github.com:", "https://github.com/")
                .replace("git@gitlab.com:", "https://gitlab.com/")
                .replace("git+", "")
                .replace(/.git$/i, "")
                .split("/")
                .filter((part) => part !== "" && part !== ":" && part !== "https:" && part !== "git:" && part !== "github.com" && part !== "gitlab.com");
            
            if (!parts || parts.length <= 0) return undefined;

            return parts.join("/");
        } catch (error) {
            return undefined;
        }
    },

    extract(value) {
        if (!value) return undefined;
        if (!value.url || value.url === "") return undefined;

        value.url = value.url.trim()
            .replace("git@github.com:", "https://github.com/")
            .replace("git@gitlab.com:", "https://gitlab.com/")
            .replace("git://github.com/", "https://github.com/")
            .replace("git://gitlab.com/", "https://gitlab.com/")
            .replace("git+", "");

        if (value.url.indexOf("https://") === -1 && value.url.indexOf("http://") === -1) return undefined;

        return value;
    },

    replacement(value, tag, href, base) {
        if (!base) return value;

        return value.replace(tag, (match) => {
            const url = match.replace(href, "$1");

            return !url.startsWith("http://") && !url.startsWith("https://") && !url.startsWith("#") ? match.replace(url, `${base}/${url}`) : match;
        });
    },
};
