export default class Cookies {
    static set(name, value, seconds) {
        const date = new Date();

        date.setTime(date.getTime() + (seconds * 1000));

        document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
    }

    static expires(name, value, timestamp) {
        document.cookie = `${name}=${value};expires=${new Date(timestamp * 1000).toUTCString()};path=/`;
    }

    static get(name) {
        name = `${name}=`;

        const cookies = decodeURIComponent(document.cookie).split(";");

        for(var i = 0; i < cookies.length; i += 1) {
            var cookie = cookies[i];

            while (cookie.charAt(0) === " ") {
                cookie = cookie.substring(1);
            }
            
            if (cookie.indexOf(name) === 0) {
                const value = cookie.substring(name.length, cookie.length);

                if (!value || value === "" || value === "null") {
                    return null;
                } else {
                    return value;
                }
            }
        }
        
        return null;
    }
}
