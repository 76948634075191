<template>
    <div ref="layout" id="private">
        <div class="screen">
            <managed />

            <div class="content manage">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
    import Managed from "@/components/managed.vue";

    export default {
        name: "private",
        components: { "managed": Managed },
    };
</script>

<style scoped>
    #private {
        display: flex;
        flex-direction: column;
        background: #fff;
        position: relative;
        min-height: 100vh;
        padding: 69px 0 0 0;
        overflow: hidden;
        box-sizing: border-box;
    }

    #private .screen {
        flex: 1;
        display: flex;
        flex-direction: row;
    }

    @media (max-width: 815px) {
        #private .screen {
            flex-direction: column;
        }
    }

    #private .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 20px 20px 0 20px;
        box-sizing: border-box;
    }
</style>