<template>
    <div id="managed">
        <div class="sticky">
            <router-link v-if="$route.name === 'manage'" to="/manage" class="active">
                <span class="icon">dashboard</span>
                Dashboard
            </router-link>

            <router-link v-else to="/manage">
                <span class="icon">dashboard</span>
                Dashboard
            </router-link>

            <router-link v-if="$route.name === 'manage-search'" to="/manage/search" class="active">
                <span class="icon">search</span>
                Search
            </router-link>

            <router-link v-else to="/manage/search">
                <span class="icon">search</span>
                Search
            </router-link>

            <router-link v-if="$route.name === 'manage-tickets'" to="/manage/tickets" class="active">
                <span class="icon">support_agent</span>
                Support Tickets
            </router-link>

            <router-link v-else to="/manage/tickets">
                <span class="icon">support_agent</span>
                Support Tickets
            </router-link>

            <router-link v-if="$route.name === 'manage-docs'" to="/manage/docs" class="active">
                <span class="icon">format_align_left</span>
                Documentation
            </router-link>

            <router-link v-else to="/manage/docs">
                <span class="icon">format_align_left</span>
                Documentation
            </router-link>

            <router-link v-if="$route.name === 'manage-topics'" to="/manage/topics" class="active">
                <span class="icon">question_answer</span>
                Q&amp;A
            </router-link>

            <router-link v-else to="/manage/topics">
                <span class="icon">question_answer</span>
                Q&amp;A
            </router-link>

            <router-link v-if="$route.name === 'manage-plugins' || $route.name === 'manage-plugin' || $route.name === 'manage-plugin-scoped'" to="/manage/plugins" class="active">
                <span class="icon">extension</span>
                Plugins
            </router-link>

            <router-link v-else to="/manage/plugins">
                <span class="icon">extension</span>
                Plugins
            </router-link>

            <router-link v-if="$route.name === 'manage-files'" to="/manage/files" class="active">
                <span class="icon">folder</span>
                Files
            </router-link>

            <router-link v-else to="/manage/files">
                <span class="icon">folder</span>
                Files
            </router-link>

            <a :href="`https://accounts.${domain}/manage/users`">
                <span class="icon">person</span>
                Users
            </a>

            <a v-if="$hasRole('ADMINISTRATORS')" :href="`https://accounts.${domain}/manage/roles`">
                <span class="icon">group</span>
                Roles
            </a>

            <router-link v-if="$route.name === 'cockpit' || $route.name === 'cockpit-termina'" to="/cockpit" class="active">
                <span class="icon">code</span>
                Remote Support
            </router-link>

            <router-link v-else to="/cockpit">
                <span class="icon">code</span>
                Remote Support
            </router-link>

            <a v-if="$hasRole('ADMINISTRATORS')" :href="`https://accounts.${domain}/manage/diagnostics`">
                <span class="icon">bug_report</span>
                Token Diagnostics
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "managed",

        computed: {
            domain() {
                const uri = window.location.hostname.split(".");
                const athority = uri.pop();
                const domain = uri.pop();

                return `${domain}.${athority}`;
            },
        },
    };
</script>

<style scoped>
    #managed {
        width: 280px;
        padding: 0 20px;
        box-sizing: border-box;
        -ms-overflow-style: none;
    }

    #managed::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 815px) {
        #managed {
            width: unset;
            padding: 10px 0 0 0;
            overflow-y: auto;
        }
    }

    #managed .sticky {
        padding: 14px 0;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }

    @media (max-width: 815px) {
        #managed .sticky {
            padding: 0;
            position: unset;
            display: flex;
            flex-direction: row;
        }
    }

    #managed a,
    #managed a:link,
    #managed a:active,
    #managed a:visited {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        text-decoration: none;
        font-size: 14px;
        padding: 14px 10px;
        border-bottom: 1px #e5e5e5 solid;
        color: #3d3d3d;
        cursor: pointer;
        user-select: none;
    }

    #managed a:last-child {
        border-bottom: 0 none;
    }

    #managed a:hover {
        text-decoration: none;
        font-weight: bold;
    }

    #managed a .icon {
        margin: 0 7px 0 0;
        font-size: 20px;
    }

    @media (max-width: 815px) {
        #managed a,
        #managed a:link,
        #managed a:active,
        #managed a:visited {
            flex: 1;
            display: unset;
            font-size: 12px;
            white-space: nowrap;
            padding: 14px 14px 20px 14px;
        }

        #managed a:last-child {
            border-bottom: 1px #e5e5e5 solid;
        }

        #managed a .icon {
            display: none;
        }
    }

    #managed .active,
    #managed .active:link,
    #managed .active:active,
    #managed .active:visited {
        font-weight: bold;
        color: #feb400;
    }

    @media (max-width: 815px) {
        #managed .active,
        #managed .active:link,
        #managed .active:active,
        #managed .active:visited {
            color: unset;
            border-bottom: 2px #feb400 solid;
        }
    }
</style>
