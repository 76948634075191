import Vue from "vue";
import Router from "vue-router";

import Authentication from "../lib/auth";

Vue.use(Router);

let manager = new Authentication();

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "home",
            component: () => import(/* webpackChunkName: "home" */ "./views/home.vue"),
            meta: { public: true, layout: "public" },
        },
        { path: "/search", redirect: "/" },
        {
            path: "/search/:query",
            name: "search",
            component: () => import(/* webpackChunkName: "home" */ "./views/search.vue"),
            props: true,
            meta: { public: true, layout: "public" },
        },
        {
            path: "/downloads",
            name: "stable-index",
            component: () => import(/* webpackChunkName: "downloads" */ "./views/downloads/stable.vue"),
            props: true,
            meta: { public: true, layout: "public" },
        },
        {
            path: "/downloads/edge",
            name: "edge-index",
            component: () => import(/* webpackChunkName: "downloads" */ "./views/downloads/edge.vue"),
            props: true,
            meta: { public: true, layout: "public" },
        },
        {
            path: "/downloads/:id",
            name: "stable-download",
            component: () => import(/* webpackChunkName: "downloads" */ "./views/downloads/stable.vue"),
            props: true,
            meta: { public: true, layout: "public" },
        },
        {
            path: "/downloads/edge/:id",
            name: "edge-download",
            component: () => import(/* webpackChunkName: "downloads" */ "./views/downloads/edge.vue"),
            props: true,
            meta: { public: true, layout: "public" },
        },
        {
            path: "/ticket",
            name: "ticket",
            component: () => import(/* webpackChunkName: "home" */ "./views/tickets/index.vue"),
            meta: { public: true, layout: "public" },
        },
        {
            path: "/topics/:topic?/:question?",
            name: "topics",
            component: () => import(/* webpackChunkName: "topics" */ "./views/topics/index.vue"),
            props: true,
            meta: { public: true, layout: "public" },
        },
        { path: "/docs/search", redirect: "/docs" },
        {
            path: "/docs/search/:query",
            name: "docs-search",
            component: () => import(/* webpackChunkName: "docs" */ "./views/documentation/search.vue"),
            props: true,
            meta: { public: true, layout: "public" },
        },
        {
            path: "/docs/:id?",
            name: "docs",
            component: () => import(/* webpackChunkName: "docs" */ "./views/documentation/index.vue"),
            props: true,
            meta: { public: true, layout: "public" },
        },
        {
            path: "/manage",
            name: "manage",
            component: () => import(/* webpackChunkName: "manage" */ "./views/manage/index.vue"),
            meta: { role: "SUPPORT", layout: "private" },
        },
        {
            path: "/manage/docs/:id?",
            name: "manage-docs",
            component: () => import(/* webpackChunkName: "manage-docs" */ "./views/manage/docs.vue"),
            props: true,
            meta: { role: "SUPPORT", layout: "private" },
        },
        {
            path: "/manage/topics/:topic?/:question?/:answer?",
            name: "manage-topics",
            component: () => import(/* webpackChunkName: "manage-topics" */ "./views/manage/topics.vue"),
            props: true,
            meta: { role: "SUPPORT", layout: "private" },
        },
        {
            path: "/manage/plugins",
            name: "manage-plugins",
            component: () => import(/* webpackChunkName: "manage-plugins" */ "./views/manage/plugins.vue"),
            meta: { role: "SUPPORT", layout: "private" },
        },
        {
            path: "/manage/plugins/:scope/:id",
            name: "manage-plugin-scoped",
            component: () => import(/* webpackChunkName: "manage-plugins" */ "./views/manage/plugins.vue"),
            props: true,
            meta: { role: "SUPPORT", layout: "private" },
        },
        {
            path: "/manage/plugins/:id",
            name: "manage-plugin",
            component: () => import(/* webpackChunkName: "manage-plugins" */ "./views/manage/plugins.vue"),
            props: true,
            meta: { role: "SUPPORT", layout: "private" },
        },
        {
            path: "/cockpit",
            name: "cockpit",
            component: () => import(/* webpackChunkName: "cockpit" */ "./views/cockpit/index.vue"),
            meta: { role: "SUPPORT", layout: "private" },
        },
        {
            path: "/cockpit/:code",
            name: "cockpit-terminal",
            component: () => import(/* webpackChunkName: "cockpit-terminal" */ "./views/cockpit/terminal.vue"),
            props: true,
            meta: { role: "SUPPORT", layout: "private" },
        },
        {
            path: "/manage/tickets/:ticket?/:post?",
            name: "manage-tickets",
            component: () => import(/* webpackChunkName: "manage-tickets" */ "./views/manage/tickets.vue"),
            props: true,
            meta: { role: "SUPPORT", layout: "private" },
        },
        {
            path: "/manage/search/:query?",
            name: "manage-search",
            component: () => import(/* webpackChunkName: "manage-search" */ "./views/manage/search.vue"),
            props: true,
            meta: { role: "SUPPORT", layout: "private" },
        },
        {
            path: "/manage/files/:folder?/:file?",
            name: "manage-files",
            component: () => import(/* webpackChunkName: "manage-tickets" */ "./views/manage/files.vue"),
            props: true,
            meta: { role: "SUPPORT", layout: "private" },
        }
    ],
});

router.beforeEach((to, _from, next) => {
    manager.user().then((user) => {
        if (user) {
            manager.roles().then((results) => {
                if (to.meta.role && results.indexOf(to.meta.role) === -1) {
                    next("/");
                } else {
                    next();
                }
            });
        } else {
            manager.renewToken().then(() => {
                if (to && to.path) {
                    window.location.href = to.path;
                } else {
                    window.location.reload();
                }
            }).catch(() => {
                if (to.meta.role) {
                    next("/");
                } else {
                    next();
                }
            });
        }
    });
});

export default router;
