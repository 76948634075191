<template>
    <div id="searchbar">
        <form v-on:submit.prevent="search()" class="search-input">
            <div class="icon">search</div>
            <input type="text" v-model="query" v-on:focus="focus()" v-on:blur="blur()" autocomplete="false" autocorrect="off" autocapitalize="none" :placeholder="holder" />
            <input type="submit" value="Search" />
        </form>
    </div>
</template>

<script>
    export default {
        name: "searchbar",

        props: {
            value: String,
            placeholder: { type: String, default: "Search Support" },
            route: { type: String, default: "/search" },
            return: { type: String, default: "/" },
        },

        data() {
            return { holder: this.placeholder, query: "" };
        },

        watch: {
            value() {
                this.query = this.value;
            },
        },

        mounted() {
            this.query = this.value;
        },

        methods: {
            focus() {
                this.holder = "";
            },

            blur() {
                this.holder = this.placeholder;
            },

            search() {
                const query = this.$encode(this.query);

                if (query && query !== "") {
                    if (this.$route.path !== `${this.route}/${query}`) this.$router.push({ path: `${this.route}/${query}` });
                } else if (this.$route.name.endsWith("search")) {
                    this.$router.push({ path: this.return });
                }
            },
        },
    };
</script>

<style scoped>
    #searchbar {
        width: 100%;
        height: 68px;
        padding: 0 14px;
        background: #fffffff0;
        border-bottom: 1px #e5e5e5 solid;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-content: center;
        align-items: center;
        position: fixed;
        top: 69px;
        left: 0;
        z-index: 100;
        box-sizing: border-box;
    }

    #searchbar .search-input {
        width: 100%;
        max-width: 1280px;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        border-radius: 3px;
        background: #fff;
        overflow: hidden;
    }

    #searchbar .search-input .icon {
        padding: 0 0 0 14px;
        color: #c7c7c7;
        height: 48px;
        border-top: 1px #e5e5e5 solid;
        border-right: 0 none;
        border-bottom: 1px #e5e5e5 solid;
        border-left: 1px #e5e5e5 solid;
        border-radius: 3px 0 0 3px;
        line-height: 48px;
    }

    #searchbar .search-input:focus-within .icon {
        border-top: 1px #feb400 solid;
        border-bottom: 1px #feb400 solid;
        border-left: 1px #feb400 solid;
    }

    #searchbar .search-input input[type="submit"] {
        height: 50px;
        box-sizing: border-box;
        border: 0 none;
        border-radius: 0 3px 3px 0;
        box-shadow: none !important;
    }

    #searchbar .search-input input[type="text"] {
        flex: 1;
        height: 48px;
        padding: 0 10px;
        margin: 0;
        border-radius: 0;
        border-top: 1px #e5e5e5 solid;
        border-right: 0 none;
        border-bottom: 1px #e5e5e5 solid;
        border-left: 0 none;
    }

    #searchbar .search-input:focus-within input[type="text"] {
        border-top: 1px #feb400 solid;
        border-bottom: 1px #feb400 solid;
    }
</style>
