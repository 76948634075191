import Request from "axios";
import Query from "querystring";
import Cookies from "../lib/cookies";
import JwtDecode from "jwt-decode";

export default {
    async token() {
        let data = JSON.tryParse(atob(Cookies.get("_client_session")));

        if (!data) {
            data = (await Request.post(`${process.env.ISSUER_URL}/connect/token`, Query.stringify({
                grant_type: "client_credentials",
                client_id: process.env.API_CLIENT_ID,
                client_secret: process.env.API_CLIENT_SECRET,
                audience: process.env.CLIENT_SCOPES,
            }), { headers: { "Content-Type": "application/x-www-form-urlencoded" } })).data;

            Cookies.expires("_client_session", btoa(JSON.stringify(data)), JwtDecode(data.access_token).exp);
        }

        return data;
    }
}
