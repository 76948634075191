<template>
    <div id="upload">
        <div class="upload-input">
            <input type="file" id="files" name="files" ref="files" multiple :accept="accept" v-on:change="select" />
        </div>

        <div class="upload-action" v-on:click="open()">
            <label v-if="droppable">
                Attach files by dragging and dropping or <a>click here to select them</a>.
            </label>

            <label v-else>
                <a>Attach files by clicking here to select them</a>.
            </label>
        </div>

        <div v-if="list" class="files">
            <div class="file" v-for="(file, index) in value" :key="`file_${index}`">{{ file.name }}<span class="icon" v-on:click="remove(index)">close</span></div>
        </div>
    </div>
</template>
    
<script>
    export default {
        name: "upload",

        props: {
            value: [Object],
            accept: String,
            droppable: Boolean,
            list: {
                type: Boolean,
                default: true,
            }
        },

        methods: {
            open() {
                this.$refs.files.click();
            },

            select(event) {
                this.value.push(...(event.target.files));
            },

            remove(key) {
                this.value.splice(key, 1);
            },
        }
    };
</script>
    
<style>
    #upload input[type="file"] {
        position: absolute;
        top: -500px;
    }

    #upload .upload-action {
        width: 100%;
        padding-bottom: 10px;
        cursor: pointer;
        user-select: none;
    }

    #upload .upload-action label {
        font-size: 12px;
        color: #9d9d9d;
        font-weight: 300;
        vertical-align: middle;
        text-align: justify;
        cursor: pointer;
    }

    #upload .files {
        flex: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        user-select: none;
    }

    #upload .files .file {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        background: #e5e5e5;
        border-radius: 3px;
        color: #3d3d3d;
        font-size: 14px;
        padding: 7px 4px 7px 7px;
        margin: 0 4px 4px 0;
        cursor: default;
        user-select: none;
    }

    #upload .files .file .icon {
        font-size: 14px;
        margin: 0 0 0 4px;
        opacity: 0.5;
        cursor: pointer;
    }

    #upload .files .file .icon:hover {
        opacity: 1;
    }
</style>
