<template>
    <div id="navigation">
        <logo :width="122" :height="25" />

        <div class="actions actions-start">
            <router-link to="/" class="desktop-only">Support</router-link>
            <router-link to="/docs" class="desktop-only">Documentation</router-link>
            <a :href="`https://plugins.${domain}`" class="desktop-only">Plugins</a>
        </div>

        <div class="actions actions-end">
            <div v-if="authenticated" v-on:click.stop="$emit('profile')" class="profile-button">
                <img :src="picture" class="profile-avatar" />
            </div>

            <div v-else v-on:click="$login()" class="action signin">Sign In</div>
            <div v-on:click.stop="$emit('menu')" class="icon mobile-only">menu</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "navigation",
        props: { authenticated: Boolean, picture: String },

        computed: {
            domain() {
                const uri = window.location.hostname.split(".");
                const athority = uri.pop();
                const domain = uri.pop();

                return `${domain}.${athority}`;
            },
        },
    };
</script>

<style scoped>
    #navigation {
        width: 100%;
        height: 69px;
        min-height: 69px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 300;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 0 30px;
        background-color: #2b2b2bf0;
        backdrop-filter: blur(6px);
        border-style: solid;
        border-image-slice: 1;
        border-width: 0 0 5px 0;
        border-color: #feb400;
        border-image-source: linear-gradient(to right, #feb400, #ff9b00, #ffe201);
    }

    #navigation .actions {
        flex: 1;
        display: flex;
        align-content: center;
        align-items: center;
    }

    #navigation .actions-start {
        padding: 0 0 0 20px;
        justify-content: flex-start;
    }

    #navigation .actions-end {
        justify-content: flex-end;
    }

    #navigation .actions a,
    #navigation .actions a:link,
    #navigation .actions a:visited,
    #navigation .actions a:active,
    #navigation .action {
        padding: 4px 20px 4px 0;
        color: #eaeaea;
        text-decoration: none;
        cursor: pointer;
        opacity: 0.8;
    }

    #navigation .actions-start a:first-child {
        justify-content: flex-start;
    }

    #navigation .actions a:hover,
    #navigation .action:hover {
        opacity: 1;
        color: #fff;
    }

    #navigation .actions .signin {
        padding: 4px 0;
    }

    #navigation .actions .icon {
        padding: 4px 0 4px 17px;
        color: #eaeaea;
        cursor: pointer;
        opacity: 0.8;
    }

    #navigation .profile-button {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }

    #navigation .profile-button .profile-avatar {
        width: 32px;
        height: 32px;
        border-radius: 5px;
    }
</style>
